import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SDMResponse } from '../interfaces/SDMResponse';
import { ToastService } from './toast.service';

interface BodySDM {
  inputParameters: [
    {
      parameterName: string;
      parameterValue: string;
    }
  ]
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private authService: AuthService, private http: HttpClient, private toastService: ToastService) { }


  public getProvinces() {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'getProvinceFarmacieRegistryMaster' }
      ]
    }
    return this.http.post(this.authService.getSDMUrl(), body).pipe(
      catchError((error: HttpErrorResponse) => {
        return of([]);
      })
    );
  }

  public getMunicipalities(codProvincia: string) {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'getComuniFarmacieByProvinciaRegistryMaster' },
        { parameterName: 'codProvincia', parameterValue: codProvincia }
      ]
    }
    return this.http.post(this.authService.getSDMUrl(), body).pipe(
      catchError((error: HttpErrorResponse) => {
        return of([]);
      })
    );
  }

  public getLinkedPharmacies() {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'getLegamiFarmacista' }
      ]
    }
    return this.http.post(this.authService.getSDMUrl(), body).pipe(
      map((res: any) => res.risposta.contenutoRisposta !== null ? res.risposta.contenutoRisposta.farmacie.map(elem => elem.farmacia) : []),
      // catchError((error: HttpErrorResponse) => {
      //   return of([]);
      // })
    );
  }

  public editLegameFarmacia(internalIDFarmacia: string, type: string, email?: string) {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'gestioneOperatoreFarmacia' },
        { parameterName: 'tipoOperazione', parameterValue: type },
        { parameterName: 'internalIDFarmacia', parameterValue: internalIDFarmacia }
      ]
    }

    console.log(type)
    if (type === 'INSERIMENTO') {
      body.inputParameters.push({ parameterName: 'emailFarmacia', parameterValue: email })
    }
    return this.http.post(this.authService.getSDMUrl(), body);
  }

  public getPharmaciesAutocomplete(codComune: string, denominazione: string) {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'getFarmacieByComuneAndDenominazioneRegistryMaster' },
        { parameterName: 'codComune', parameterValue: codComune },
        { parameterName: 'denominazione', parameterValue: denominazione }
      ]
    }
    return this.http.post(this.authService.getSDMUrl(), body).pipe(
      catchError((res: HttpErrorResponse) => {
        return of([]);
      })
    );
  }

  public getPromemoria(codiceFiscale: string, nre: string) {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: 'getDM2011FarPrePromemoriaByCFNRESAC' },
        { parameterName: 'codiceFiscale', parameterValue: codiceFiscale },
        { parameterName: 'nre', parameterValue: nre },
        { parameterName: 'operatoreCup', parameterValue: 'true' }
      ]
    }
    return this.http.post(`${this.authService.getSDMUrl()}?client-type=rt-farma-client&idDominio=getDM2011FarPrePromemoriaByCFNRESAC`, body).pipe(
      map((res: any) => res.risposta),
    );
  }

  public getEspandiPromemoria(codiceFiscale: string, nre: string) {
    const body = {
      inputParameters: [
        { parameterName: 'idDominio', parameterValue: this.authService.getIdDominioEstendiRicerca() },
        { parameterName: 'codiceFiscale', parameterValue: codiceFiscale },
        { parameterName: 'nre', parameterValue: nre },
        { parameterName: 'operatoreCup', parameterValue: 'true' }
      ]
    }
    return this.http.post(`${this.authService.getSDMUrl()}?client-type=rt-farma-client&idDominio=${this.authService.getIdDominioEstendiRicerca()}`, body).pipe(
      map((res: any) => res.risposta),
    );
  }

  public getPromemoriaRicetteTrasporti(codiceFiscale: string, nre: string) {

    return this.http.get(this.authService.getRicetteTrasportiStampaUrl() + '/pdf/V2/' + nre + '/' + codiceFiscale, { responseType: 'arraybuffer' })
  }
}
