import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastService } from './services/toast.service';
import { ApiService } from './services/api.service';
import { AuthorityManagerService } from './services/authority-manager.service';

@Injectable()
export class IsAuthenticatedGuard implements CanActivate {

  operatoreCup;


  constructor(private authService: AuthService, private router: Router, private toastService: ToastService, private apiService: ApiService, private authorityManagerService: AuthorityManagerService) { }


  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    boolean {

    if (this.authService.isAuthenticated('recoverNRE')) {
      return this.authorityManagerService.getProfiloOperatoreCup();
    }

  }




  //return this.authService.isAuthenticated('recoverNRE')
  /*     if (this.authService.isAuthenticated('recoverNRE')) {
        return this.apiService.getLinkedPharmacies().pipe(
          map((res: any[]) => {
            if (res.length > 0) return true;
            else {
              this.toastService.showToast('E\' necessario avere almeno un\'associazione con una farmacia per la quale lavori.', 'OK');
              this.router.navigateByUrl('/le-mie-farmacie', {
                state: {
                  modalTitle: 'Avviso importante',
                  modalText: `
  Non ci sono farmacie associate.
  
  Da questo momento è necessario avere almeno un\'associazione con una farmacia per la quale lavori.
  Se lavori per più farmacie, si possono creare più associazioni.
  
  Per accedere a tutte le funzionalità utilizzare il menù in alto a sinistra (≡)
                `,
                  subtitle: true
                }
              });
            }
  
          }),
          catchError(() => {
            this.router.navigateByUrl('/le-mie-farmacie', {
              state: {
                modalTitle: 'Avviso importante',
                modalText: 'Si è verificato un errore.'
              }
            });
            return of(false);
          })
        )
      } */

}