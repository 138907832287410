import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  public showToast(text: string, action: string, duration?: number) {
    this.snackBar.open(text, action, {
      verticalPosition: 'top',
      duration: duration ? duration : 10000,
    });
  }
}
