<div *ngIf="!isLoading" class="content-home">
    <div *ngIf="avvisoDisservizio" class="alert-box"
        style="margin:auto; width:70%; margin-bottom: 50px; font-size: 20px;">
        <p>ATTENZIONE. Il prossimo 30/10 dalle ore 18:00 alle ore 20:00 il servizio non sarà disponibile a causa
            di un
            intervento manutentivo.</p>
    </div>
    <div class="main-title">
        <h1 class="main-title-text">Servizio consultazione ricette</h1>
    </div>

    <button type="button" class="button-accedi" (click)="accedi()">ACCEDI</button>


    <div class="third-text">
        <p class="text-home-2">Questo servizio è riservato agli operatori degli sportelli CUP di Regione Toscana.</p>
        <p class="text-home-2">
            L'utilizzo di questo strumento è monitorato e tracciato per ragioni di sicurezza informatica.
            Ogni abuso sarà perseguito a norma di legge.
        </p>
    </div>

    <div class="mailTO">
        <a class="text-home-2" href="./../../../assets/Consultazione_Ricette_manuale.pdf" target=”_blank”> Manuale per
            l'autenticazione e
            l'utilizzo</a>
    </div>

    <div>
        <p class="text-home-2">Help Desk Tel: <a href="callto:800558080">800558080</a>, mail: <a
                href="mailto:helpsis@regione.toscana.it">helpsis@regione.toscana.it</a>, lunedì-sabato ore 8:00–18:00
        </p>
    </div>
</div>

<div *ngIf="isLoading" class="content-home">
    <div>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
</div>