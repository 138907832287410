import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { SDMResponse } from '../interfaces/SDMResponse';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorityManagerService {

  constructor(private http: HttpClient) { }

  profiloOperatoreCup: boolean;

  public getProfili(codiceFiscale: string, urlAuthorityManager: string) {
    return this.http.get<any>(urlAuthorityManager + `/profiloUtente/findAll?codiceFiscale=${codiceFiscale}&asset=ricettecup`);
  }

  getProfiloOperatoreCup() {
    return this.profiloOperatoreCup;
  }

  setProfiloOperatoreCup(operatoreCupFlag) {
    this.profiloOperatoreCup = operatoreCupFlag;
  }
}
