import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import { AuthorityManagerService } from './authority-manager.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private accessToken: string;
  private refreshToken: string;
  firstPartToken;
  thirdPartToken;
  operatoreCup;
  isLoading;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private location: Location, private router: Router, private authorityManagerService: AuthorityManagerService) {
    switch (window.location.hostname) {
      case 'localhost': {
        this.redirect_uri = 'http://localhost:4200/login';
        this.config = {
          idDominioEstendiRicerca: 'getDM2011FarPrePromemoriaByCFnotEqualNREConPrerequisitoSAC',
          logoutUrl: `https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/logout?redirect_uri=${this.redirect_uri}&accessToken=`,
          tokenUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
          authorityManager: 'sis_authority_manager',
          stampaPromemoriaRicetteTrasporti: 'ricette_trasporti_stampa',
          sdmUrl: 'sdm-noarpa/rtsanita/gestoreInformazioneSanitariaFlat.json',
          accessosicuroUrl: `https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth?&response_type=code&client_id=rt-farma-client&scope=rt-farma/farma profile address email phone&redirect_uri=${this.redirect_uri}`,
        }
        break;
      };
      case 'ricettecup-test.sanita.toscana.it': {
        this.redirect_uri = 'https://ricettecup-test.sanita.toscana.it';
        this.config = {
          idDominioEstendiRicerca: 'getDM2011FarPrePromemoriaByCFnotEqualNREConPrerequisitoSAC',
          logoutUrl: `https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/logout?redirect_uri=${this.redirect_uri}&accessToken=`,
          tokenUrl: 'https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
          sdmUrl: 'https://apistage.regione.toscana.it/C01/rt-farma-arpa/v1',
          //authorityManager: 'https://ws-apigw-test.rt.tix.it/siscommon/aam/sis_authority_manager',
          authorityManager: 'https://apistage.regione.toscana.it/C01/AttributeAuthorityManager/v1/sis_authority_manager',
          stampaPromemoriaRicetteTrasporti: 'https://apistage.regione.toscana.it/sanita/C06/prescrizione-trasporti/v1',
          accessosicuroUrl: `https://accessosicuro-trial.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth?response_type=code&client_id=rt-farma-client&scope=rt-farma-arpa-rs/farma attributeauthoritymanager-rs/audience prescrizione-rs/audience profile address email phone&redirect_uri=${this.redirect_uri}`,
        }
        break;
      };
      case 'ricettecup.sanita.toscana.it': {
        this.redirect_uri = 'https://ricettecup.sanita.toscana.it';
        this.config = {
          idDominioEstendiRicerca: 'getDM2011FarPrePromemoriaByCFnotEqualNREConPrerequisitoSAC',
          logoutUrl: `https://accessosicuro.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/logout?redirect_uri=${this.redirect_uri}&accessToken=`,
          tokenUrl: 'https://accessosicuro.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/token',
          sdmUrl: 'https://api.regione.toscana.it/C01/rt-farma-arpa/v1',
          authorityManager: 'https://api.regione.toscana.it/C01/AttributeAuthorityManager/v1/sis_authority_manager',
          stampaPromemoriaRicetteTrasporti: 'https://api.regione.toscana.it/sanita/C06/prescrizione-trasporti/v1',
          accessosicuroUrl: `https://accessosicuro.rete.toscana.it/auth/realms/arpa/protocol/openid-connect/auth?response_type=code&client_id=rt-farma-client&scope=rt-farma-arpa-rs/farma attributeauthoritymanager-rs/audience prescrizione-rs/audience profile&redirect_uri=${this.redirect_uri}`,
        }
        break;
      };
      case 'servizi.toscana.it': {
        this.redirect_uri = 'https://servizi.toscana.it/sis/prescrizione/promemoria/login';
        this.config = {
          idDominioEstendiRicerca: 'getDM2011FarPrePromemoriaByCFnotEqualNREConPrerequisitoSAC',
          logoutUrl: `https://accessosicuro.rete.toscana.it/idp/realms/rt/protocol/openid-connect/logout?redirect_uri=${this.redirect_uri}&accessToken=`,
          tokenUrl: 'https://accessosicuro.rete.toscana.it/idp/realms/rt/protocol/openid-connect/token',
          sdmUrl: 'https://api.regione.toscana.it/C01/rt-farma/v1',
          authorityManager: '',
          stampaPromemoriaRicetteTrasporti: '',
          accessosicuroUrl: `https://accessosicuro.rete.toscana.it/idp/realms/rt/protocol/openid-connect/auth?&response_type=code&client_id=rt-farma-client&scope=rt-farma/farma profile address email phone&redirect_uri=${this.redirect_uri}`,
        }
        break;
      };
    };

  }


  setIsLoading(boolean) {
    this.isLoading = boolean;
  }

  getIsLoading() {
    return this.isLoading;
  }

  // private timerRefreshToken$ = interval(60 * 1000).pipe(
  private timerRefreshToken$ = interval(25 * 60 * 1000).pipe(
    // switchMap(() => this.fetchRefreshToken())
    flatMap(() => this.fetchRefreshToken())
  );

  startTimer() {
    this.timerRefreshToken$.subscribe();
  }

  private landingPageUrl = '';
  public redirect_uri = '';
  public config: {
    idDominioEstendiRicerca: string;
    tokenUrl: string;
    logoutUrl: string;
    accessosicuroUrl: string;
    authorityManager: string;
    stampaPromemoriaRicetteTrasporti: string;
    sdmUrl: string;
  } = {
      idDominioEstendiRicerca: '',
      logoutUrl: '',
      tokenUrl: '',
      accessosicuroUrl: '',
      authorityManager: '',
      stampaPromemoriaRicetteTrasporti: '',
      sdmUrl: ''
    };


  isAuthenticated(route: string) {
    this.accessToken = sessionStorage.getItem('accessToken')
    if (this.accessToken) {
      this.startTimer();
      return true;
    }
    this.authenticate();
  }

  onlyUnique(value, index, self) {
    console.log(value)
    console.log(index)

    return self.indexOf(value.ruolo) == index.ruolo;
  }


  public decodeToken(token) {
    this.firstPartToken = token.split('.')[0];
    this.thirdPartToken = token.split('.')[2];
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return jsonPayload;
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  authenticate() {
    if (window.location.hostname == 'localhost') {
      console.log("Develop mode")
      sessionStorage.setItem('accessToken', "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJnZ3VFdlBBTS1rN0Jsa0xJMnN0VFN1Q0xVcXJsbDdwUE9JaWhsZUNFRWJZIn0.eyJleHAiOjE2NjI3MTg1NDMsImlhdCI6MTY2MjcxODI0MywiYXV0aF90aW1lIjoxNjYyNzE0ODMxLCJqdGkiOiIxMjY4MDlhMi0wMjgzLTRhYTctODFhZi1kODJjMzUyMDAwOTciLCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJhdWQiOlsicnQtZmFybWEtYXJwYS1ycyIsImF0dHJpYnV0ZWF1dGhvcml0eW1hbmFnZXItcnMiLCJydC1mYXJtYSJdLCJzdWIiOiJlMjM4NTg2NC02ZTEyLTQzMGEtOWFkYy01NTkxNWE3NTQ0NTQiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJydC1mYXJtYS1jbGllbnQiLCJzZXNzaW9uX3N0YXRlIjoiMDQ3Y2Y3MGEtM2QzYS00MjgwLTg4NTMtMWY4YTgzMzA4ZTBlIiwiYWNyIjoiMSIsInNjb3BlIjoicHJvZmlsZSBvZmZsaW5lX2FjY2VzcyBwaG9uZSBhZGRyZXNzIGVtYWlsIHJ0LWZhcm1hLWFycGEtcnMvZmFybWEgZGVmYXVsdCIsInNpZCI6IjA0N2NmNzBhLTNkM2EtNDI4MC04ODUzLTFmOGE4MzMwOGUwZSIsImZpc2NhbF9udW1iZXIiOiJUSU5JVC1GUk1UVFI3Nk0wNkI3MTVFIiwiYXV0aF90eXBlIjoiU3BpZFRlc3QiLCJhZGRyZXNzIjp7fSwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJhdXRoX2xldmVsIjoiMyIsIm5hbWUiOiJFVFRPUkUiLCJzcGlkX2NvZGUiOiJjYmMxNmMxNy0yOTRlLTQzZDMtYTFmMS0zN2YxMDE5YWRmNjEiLCJhdXRoVFMiOiIxNjYyNzE0ODMwMjU4IiwicHJlZmVycmVkX3VzZXJuYW1lIjoiRlJNVFRSNzZNMDZCNzE1RSIsImZhbWlseV9uYW1lIjoiRklFUkFNT1NDQSIsImVtYWlsIjoidHJlZGljaWl0YWxpYW5pY29udHJvdHJlZGljaWZyYW5jZXNpQGhvdG1haWwuY29tIiwiYXV0aElEIjoiMDFfdGVzdDI4XzE2NjI3MTQ4MzAwOTNfMDQ4NDEifQ.B4Sk7v5gkNQZIaYN45urUXZ4VuGMvMi5dvMxCXwkq9PGq-ki0xuh-wk4Yyy22nEIBg8Q4iF2ljuSrQse0vyVj7qcQE60TDbxjXx_MfZX5YFzwmnXIytqR-p-4ZGhmHRPPXZBO-802pxhQsnZuVXLG3OS0LXvI9ZnMM07vtFyFhMlysZEYKYTW3NE0dLWF-NTb0E9bVy54RVjm6-Q393SSe2Vou-TJVXImFdb3Wzk46-xuNUvYgR74VN9s4Rt1X6K1G_b7Kufk1J17O750K5gq1OvHQIQWV44WVwqfaplXl7ekCbVIsl40OjdzgsICi3Oe-0xftDr8avZKsVOgLfC6g")
      sessionStorage.setItem('refreshToken', "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICIxOGQxZWVlYi1mM2MwLTRhZjYtOTZlMS00YTExNmRiODMwNTgifQ.eyJqdGkiOiJhOTA2YWFlZS1kMzM3LTQxMTAtYmYzMS0wZDU2OTRhOGFjMzIiLCJleHAiOjAsIm5iZiI6MCwiaWF0IjoxNjI1MDUwODA3LCJpc3MiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJhdWQiOiJodHRwczovL2FjY2Vzc29zaWN1cm8tdHJpYWwucmV0ZS50b3NjYW5hLml0L2F1dGgvcmVhbG1zL2FycGEiLCJzdWIiOiJhYzMwOThlNC0xYmE2LTQ2ZjQtOThhMi0zNmY5MTMwZDlmYzkiLCJ0eXAiOiJPZmZsaW5lIiwiYXpwIjoicnQtZmFybWEtY2xpZW50IiwiYXV0aF90aW1lIjowLCJzZXNzaW9uX3N0YXRlIjoiNWUwODkzY2ItMjdmMy00MzdhLTg3OGUtMTVmMzc5MTBiMWEwIiwic2NvcGUiOiJwcm9maWxlIG9mZmxpbmVfYWNjZXNzIHJ0LWZhcm1hL2Zhcm1hIHBob25lIGFkZHJlc3MgZW1haWwgZGVmYXVsdCJ9.rnky3gBFUZG3K5STW8kHKNW8wzdlpOo9gM2A2cjhfQ4")
    }
    if (sessionStorage.getItem("accessToken") == null) {
      location.replace(this.config.accessosicuroUrl)
    }
  }

  initAuthorization() {
    let code = (this.getParameterByName('code', window.location.href));
    if (code != null) {
      this.isLoading = true;
      let httpClientOptions: any = {}
      let body = '';

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
      httpClientOptions.headers = headers;

      let queryParams = new HttpParams();
      queryParams = queryParams.set('response_type', 'code');
      httpClientOptions.params = queryParams;
      body = 'grant_type=' + 'authorization_code' + '&code=' + code + '&client_id=' + 'rt-farma-client' + '&redirect_uri=' + this.redirect_uri // + route;

      httpClientOptions.body = body;


      this.http.request('POST', this.config.tokenUrl, httpClientOptions).subscribe((res: any) => {
        this.accessToken = res.access_token;
        this.refreshToken = res.refresh_token;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        sessionStorage.setItem('accessToken', res.access_token)
        sessionStorage.setItem('refreshToken', res.refresh_token)

        this.getProfile();
      });
    }
  }

  getProfile() {
    let tokenJSON = JSON.parse(this.decodeToken(sessionStorage.getItem('accessToken')));
    let codiceFiscale = tokenJSON['preferred_username'];
    this.authorityManagerService.getProfili(codiceFiscale, this.config.authorityManager).subscribe(result => {
      let listaRuoli = [];
      for (let ruolo of result.response) {
        listaRuoli.push(ruolo.listaValoriAttributo)
      };
      let operatoreCup = listaRuoli.find(item => item == 'operatoreCup');
      if (operatoreCup) {
        this.operatoreCup = true;
        this.authorityManagerService.setProfiloOperatoreCup(true)
        console.log("operatoreCup", this.operatoreCup)
        this.router.navigate(['recoverNRE'])

      } else {
        console.log("utente non abilitato")
        this.authorityManagerService.setProfiloOperatoreCup(false)
        this.router.navigate(['utente_non_abilitato'])
      }
    },
      err => {

      })
  }


  fetchRefreshToken() {
    let httpClientOptions: any = {}
    let body = '';

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    httpClientOptions.headers = headers;

    // let queryParams = new HttpParams();
    // httpClientOptions.params = queryParams;
    body = 'grant_type=' + 'refresh_token' + '&refresh_token=' + this.getRefreshToken() + '&client_id=' + 'rt-farma-client' + '&redirect_uri=' + this.redirect_uri;

    httpClientOptions.body = body;

    return this.http.request('POST', this.config.tokenUrl, httpClientOptions).pipe(
      tap((response: any) => {
        this.accessToken = response.access_token
        this.refreshToken = response.refresh_token
        this.setAccessToken(response.access_token)
        this.setRefreshToken(response.refresh_token)
      })
    );
  }

  public getSDMUrl(): string {
    return this.config.sdmUrl;
  }

  public getRicetteTrasportiStampaUrl(): string {
    return this.config.stampaPromemoriaRicetteTrasporti;
  }

  public getIdDominioEstendiRicerca(): string {
    return this.config.idDominioEstendiRicerca;
  }
  public getAccessToken(): string {
    return sessionStorage.getItem('accessToken');
  }
  public getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }
  public setAccessToken(token: string) {
    return sessionStorage.setItem('accessToken', token);
  }
  public setRefreshToken(token: string) {
    return sessionStorage.setItem('refreshToken', token);
  }

  public getLoginUrl(): string {
    return this.config.accessosicuroUrl;
  }
  public logout(): void {
    location.replace(`${this.config.logoutUrl}${this.getAccessToken()}`);
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
  }
}
