<div class="grid-layout">
  <mat-toolbar class="mat-toolbar--taller" fxLayout="row" fxLayoutAlign="space-between center">
    <button *ngIf="isNotHome()" mat-icon-button aria-label=" Menu button" (click)="sidenav.toggle()">
      <mat-icon color="primary">menu</mat-icon>
    </button>
    <p *ngIf="!isNotHome()" mat-icon-button aria-label=" Menu button">
    </p>

    <div fxLayout="row" fxLayoutGap="24px">

      <div fxLayout="column" class="navbar-title">
        <p>SISTEMA INFORMATIVO UNICO REGIONALE</p>
        <p>PER LA GESTIONE EMERGENZA CoViD19</p>
      </div>

      <div class="RT-images" fxHide.lt-md>
        <img src="assets/logoRT.png" />
        <img src="assets/SST.png" />
      </div>

    </div>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" class="menu">
      <mat-nav-list role="menu-list">
        <!--         <a *ngIf='!operatoreCup' mat-list-item role="listitem" routerLink="/le-mie-farmacie" (click)="sidenav.close()">
          <mat-icon color="primary" class="menu-icon">store</mat-icon>
          Le mie farmacie
        </a> -->
        <a mat-list-item role="listitem" routerLink="/recoverNRE" (click)="sidenav.close()">
          <mat-icon color="primary" class="menu-icon">search</mat-icon>
          Cerca NRE
        </a>
        <mat-divider></mat-divider>
        <mat-list-item role="listitem" (click)="logout()" (click)="sidenav.close()">
          <mat-icon color="warn" class="menu-icon">exit_to_app</mat-icon>
          Logout
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <mat-toolbar class="footer">
    <div class="footer-container" fxLayout="column" fxLayoutAlign="start" fxHide.sm fxHide.xs fxShow.gt-xs>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span>
          Help Desk SIS - Numero Verde: <a href="tel:800558080" tabindex="-1">800 558080</a> - mail: <a
            href="mailto:helpsis@regione.toscana.it" tabindex="-1">helpsis@regione.toscana.it</a> - Fax: 06-91868952
        </span>
        <span>
          version {{ version }}
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <span>
          Orario Servizio di Help Desk: lunedì-sabato ore 8:00 – 18:00
        </span>
        <a href="assets/Consultazione_Ricette_manuale.pdf" target="_blank" tabindex="-1">Manuale Utente</a>
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start" fxHide.gt-sm fxShow.xs fxShow.sm>
      <span>
        Help Desk SIS - Mail: <a href="mailto:helpsis@regione.toscana.it" tabindex="-1">helpsis@regione.toscana.it</a>
      </span>
      <span>
        N. verde: <a href="tel:800558080" tabindex="-1">800 558080</a> Fax: 06-91868952
      </span>
      <span>
        Orario Help Desk: lunedì-sabato ore 8:00 – 18:00
      </span>
      <span>
        version {{ version }}
      </span>
      <span>
        <a href="assets/Consultazione_Ricette_manuale.pdf" target="_blank" tabindex="-1">Manuale Utente</a>
      </span>
    </div>
  </mat-toolbar>
</div>