import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { version } from '../../package.json'
import { AuthorityManagerService } from './services/authority-manager.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'Consultazione ricette';
  public version = version;
  operatoreCup;

  constructor(private authService: AuthService, private authorityManagerService: AuthorityManagerService, private router: Router) { }

  ngOnInit() {
    this.authService.initAuthorization();
  }


  isNotHome() {
    if (window.location.href.includes("home") || window.location.href.includes("utente_non_abilitato")) {
      return false;
    } else {
      return true;
    }
  }

  public logout() {
    this.authService.logout();
  }
}
