<div class="page" fxLayout="column" fxLayoutGap="24px">
  <div fxLayout="row" fxLayoutAlign="center center">
    <form [formGroup]="recoverForm" (ngSubmit)="submit()">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-xs="row wrap" fxLayoutGap="24px"
        class="login-form">
        <mat-form-field appearance="fill" fxFlex="55" class="form-field">
          <mat-label>Codice Fiscale Assistito</mat-label>
          <input id="SSNinput" type="text" matInput autofocus formControlName="SSN" [errorStateMatcher]="matcher"
            [matAutocomplete]="auto" />
          <button type="button" tabindex="-1" mat-button *ngIf="SSN.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="SSN.setValue('')">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ option }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="
            SSN.hasError('pattern') &&
              !SSN.hasError('required')
            ">
            Inserisci un CF valido
          </mat-error>
          <mat-error *ngIf="SSN.hasError('required')">
            Il CF è <strong>obbligatorio</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="40" class="form-field">
          <mat-label>NRE: almeno ultimi 5 caratteri</mat-label>
          <input matInput formControlName="last5NREDigits" [errorStateMatcher]="matcher" />
          <!-- <mat-error
            *ngIf="
            last5NREDigits.hasError('pattern') &&
              !last5NREDigits.hasError('required')
            "
          >
            Inserisci una sequenza di numeri valida
          </mat-error> -->
          <mat-error *ngIf="
            last5NREDigits.hasError('minlength') &&
              !last5NREDigits.hasError('required')
            ">
            Inserisci almeno gli ultimi 5 caratteri
          </mat-error>
          <mat-error *ngIf="last5NREDigits.hasError('required')">
            NRE <strong>obbligatorio</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <button mat-flat-button color="primary" class="login-form" [disabled]="!recoverForm.valid">
        Recupera
      </button>
    </form>
  </div>

  <ng-container *ngIf="submitted">
    <ng-container *ngIf="stampaRicetteTrasportiBoolean">
      <div>
        <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center">
          <span>Codice fiscale: <b>{{ codiceFiscale }}</b></span>
        </div>
        <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center">
          <span>NRE: <b>{{nre}}</b></span>
        </div>
        <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center">
          <span><b>NRE RICETTA TRASPORTI</b></span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="NREList$ | async as NREList; else loadingOrError">
      <div class="error-msg" fxLayout="row" fxLayoutAlign="center center" *ngIf="errorMsg">
        <span> {{ errorMsg }} </span>
      </div>
      <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center"
        *ngIf="errorMsgRicettaNonConsultabile">
        <span>Codice fiscale: <b>{{ codiceFiscale }}</b></span>
      </div>
      <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center"
        *ngIf="errorMsgRicettaNonConsultabile">
        <span>NRE: <b>{{nre}}</b></span>
      </div>
      <div class="error-msg-ricettaNonConsultabile" fxLayout="row" fxLayoutAlign="center center"
        *ngIf="errorMsgRicettaNonConsultabile">
        <span>Non è possibile consultare la ricetta: <b>{{ errorMsgRicettaNonConsultabile }}</b></span>
      </div>
      <mat-accordion multi="false">
        <div *ngFor="let NREdata of NREList; let i = index">
          <ng-container *ngIf="singleNRE; else list">
            <div fxLayout="row" fxLayoutAlign="center center">
              <mat-card class="example-card mat-card-no-actions">
                <mat-card-header>
                  <div mat-card-avatar class="example-header-image"></div>
                  <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="grow">
                    <mat-card-title *ngIf="!singoloEsteso; else estesoConData">
                      <span>{{ splitNRE(NREdata, 10) }}</span>
                      <span class="colored-nre">{{ splitNRE(NREdata, 5) }}</span>
                      <button class="clipboard-button" mat-flat-button color="primary"
                        (click)="copyToClipboard(NREdata)" fxHide.xs>
                        COPIA NRE
                        <span matTooltip="NRE copiato, puoi incollare" #tooltip="matTooltip"></span>
                      </button>
                    </mat-card-title>
                    <ng-template #estesoConData>
                      <mat-card-title>
                        <span>{{ splitNRE(NREdata.nre, 10) }}</span>
                        <span class="colored-nre">{{ splitNRE(NREdata.nre, 5) }} </span>
                        <button class="clipboard-button" mat-flat-button color="primary"
                          (click)="copyToClipboard(NREdata.nre)" fxHide.xs>
                          COPIA NRE
                          <span matTooltip="NRE copiato, puoi incollare" #tooltip="matTooltip"></span>
                        </button>
                      </mat-card-title>
                    </ng-template>

                    <div fxLayout="row" fxLayoutGap="8px">
                      <button mat-flat-button color="primary" *ngIf="!extended" (click)="extendSearch(NREdata)">
                        <mat-icon>search</mat-icon>
                        <span fxHide fxShow.gt-xs>
                          Espandi
                        </span>
                      </button>
                      <a mat-flat-button color="primary" [href]="singlePdfBase64" [download]="singlePdfFilename"
                        color="{{ clickedDownLoad ? 'warn' : 'primary' }}" (click)="clickedDownLoad = true">
                        <mat-icon *ngIf="clickedDownLoad">done_all</mat-icon>
                        <mat-icon>print</mat-icon>
                        <span fxHide fxShow.gt-xs>
                          Stampa
                        </span>
                      </a>
                    </div>
                    <!-- <a mat-flat-button color="primary" [href]="singlePdfBase64" [download]="singlePdfFilename"> Stampa </a> -->
                  </div>
                </mat-card-header>
                <img mat-card-image [src]="singleImageBase64" alt="NRE" />
              </mat-card>
            </div>
          </ng-container>

          <ng-template #list>
            <div fxLayout="column" fxLayoutAlign="center center">
              <ng-container *ngIf="extended; else multiple">
                <mat-expansion-panel class="example-card accordion-item" (opened)="searchSingleNRE(NREdata.nre, i)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
                        <div>
                          <span>{{ splitNRE(NREdata.nre, 10) }}</span>
                          <span class="colored-nre">{{splitNRE(NREdata.nre, 5)}}</span>
                        </div>

                        <mat-icon *ngIf="!openedStateArr[i]; else visible" color="primary">visibility_off</mat-icon>
                        <ng-template #visible>
                          <mat-icon color="{{ printedPdfArr[i] ? 'warn' : 'primary' }}">visibility</mat-icon>
                        </ng-template>

                        <small>
                          {{ NREdata.dataPrescrizione }}
                        </small>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div *ngIf="NRESingleArr$[i]">
                    <ng-container *ngIf="
                        NRESingleArr$[i] | async as NREsingle;
                        else loadingOrErrorSingle
                      ">
                      <mat-card class="example-card inside-accordion mat-card-no-actions">
                        <mat-card-header>
                          <div mat-card-avatar class="example-header-image"></div>
                          <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="grow">
                            <mat-card-title>
                              <span>{{ splitNRE(NREdata.nre, 10) }}</span>
                              <span class="colored-nre">{{
                                splitNRE(NREdata.nre, 5)
                                }}</span>
                              <button class="clipboard-button" mat-flat-button color="primary"
                                (click)="copyToClipboard(NREdata.nre)" fxHide.xs>
                                COPIA NRE
                                <span matTooltip="NRE copiato, puoi incollare" #tooltip="matTooltip"></span>
                              </button>
                            </mat-card-title>
                            <div fxLayout="row" fxLayoutGap="8px">
                              <button mat-flat-button color="primary" *ngIf="!extended"
                                (click)="extendSearch(NREdata.nre)">
                                <mat-icon>search</mat-icon>
                                <span fxHide fxShow.gt-xs>
                                  Espandi
                                </span>
                              </button>
                              <a mat-flat-button color="{{
                                  printedPdfArr[i] ? 'warn' : 'primary'
                                }}" [href]="openedLoadedArr[i].singlePdfBase64" [download]="
                                  openedLoadedArr[i].singlePdfFilename
                                " (click)="flagAsPrinted(i)">
                                <mat-icon *ngIf="printedPdfArr[i]">done_all</mat-icon>
                                <mat-icon>print</mat-icon>
                                <span fxHide fxShow.gt-xs>
                                  Stampa
                                </span>
                              </a>
                            </div>
                            <!-- <a mat-flat-button color="primary" [href]="singlePdfBase64" [download]="singlePdfFilename"> Stampa </a> -->
                          </div>
                        </mat-card-header>
                        <img mat-card-image [src]="openedLoadedArr[i].singleImageBase64" alt="NRE" />
                      </mat-card>
                      <!-- <a mat-flat-button color="primary" class="stampa-extend" [href]="openedLoadedArr[i].singlePdfBase64" [download]="openedLoadedArr[i].singlePdfFilename">
                      Scarica PDF
                      <mat-icon>print</mat-icon>
                    </a>
                    <img mat-card-image [src]="openedLoadedArr[i].singleImageBase64" alt="NRE" /> -->
                    </ng-container>

                    <ng-template #loadingOrErrorSingle>
                      <ng-container *ngIf="errorMsgSingle; else loadingSingle">
                        <div class="error-msg" fxLayout="row" fxLayoutAlign="center center">
                          <span> {{ errorMsgSingle }} </span>
                        </div>
                      </ng-container>

                      <ng-template #loadingSingle>
                        <div fxLayout="row" fxLayoutAlign="center center">
                          <mat-spinner></mat-spinner>
                        </div>
                      </ng-template>
                    </ng-template>
                  </div>
                </mat-expansion-panel>
              </ng-container>

              <ng-template #multiple>
                <mat-card class="example-card accordion-item">
                  <mat-card-header>
                    <div mat-card-avatar class="example-header-image"></div>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="grow">
                      <mat-card-title>
                        <span>{{ splitNRE(NREdata, 10) }}</span>
                        <span class="colored-nre">{{
                          splitNRE(NREdata, 5)
                          }}</span>
                      </mat-card-title>
                      <button mat-flat-button color="primary" (click)="submit(NREdata)">
                        <mat-icon>search</mat-icon>
                        <span fxHide fxShow.gt-xs>
                          Seleziona
                        </span>
                      </button>
                    </div>
                  </mat-card-header>
                </mat-card>
              </ng-template>
            </div>
          </ng-template>
        </div>
      </mat-accordion>
    </ng-container>

    <ng-template #loadingOrError>
      <ng-container *ngIf="errorMsg; else loading">
        <div class="error-msg" fxLayout="row" fxLayoutAlign="center center">
          <span> {{ errorMsg }} </span>
        </div>
      </ng-container>

      <ng-template #loading>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!stampaRicetteTrasportiBoolean">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </ng-template>
  </ng-container>
</div>