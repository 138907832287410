import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { AuthService } from './services/auth.service';
import { catchError, switchMap, filter, take, tap, map } from 'rxjs/operators';
import { ToastService } from './services/toast.service';
import { SDMResponse } from './interfaces/SDMResponse';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private toastService: ToastService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const accessToken = this.authService.getAccessToken();

    if (accessToken && !request.url.includes('accessosicuro')) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      // tap((response: HttpResponse<any>) => {
      //   console.log('response TAP TAP', request.url)
      //   if (request.url.includes('gestoreInformazioneSanitariaFlat'))
      //   console.dir(response.body)
        
      //   this.toastService.showToast('success!', 'OK')
      //   return response.body
      // }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          // console.log('not a 401 Error')
          if (request.url.includes('accessosicuro')) this.authService.logout();

          // se non e' un errore di accesso sicuro entra qui
          this.toastService.showToast(`Errore imprevisto. Riprovare più tardi.`, 'OK')

          return throwError(error);
        }
      }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      // console.log('is not refreshing')
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.fetchRefreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access_token);
          this.authService.setAccessToken(token.access_token);
          this.authService.setRefreshToken(token.refresh_token);
          return next.handle(this.addToken(request, token.access_token));
        }));

    } else {
      // console.log('is refreshing')
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(access_token => {
          return next.handle(this.addToken(request, access_token));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
