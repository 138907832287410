import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecoverNREComponent } from './recover-nre/recover-nre.component';
import { LoginComponent } from './login/login.component';
import { IsAuthenticatedGuard } from './is-authenticated-guard';
import { AddFarmaciaComponent } from './add-farmacia/add-farmacia.component';
import { IsAuthenticatedPharmaGuard } from './is-authenticated-pharma.guard';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { HomeComponent } from './page/home/home.component';

const routes: Routes = [
  //{ path: 'recoverNRE', component: RecoverNREComponent, canActivate: [IsAuthenticatedGuard] },
  { path: 'recoverNRE', component: RecoverNREComponent },
  //{ path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'utente_non_abilitato', component: UtenteNonAbilitatoComponent },
  //{ path: 'le-mie-farmacie', component: AddFarmaciaComponent, canActivate: [IsAuthenticatedPharmaGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
  /* { path: '', redirectTo: '/login', pathMatch: 'full' } */
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
