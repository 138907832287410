<div class="page" fxLayout="column" fxLayoutGap="24px">
  <h2 class="title-centered">Ricerca farmacia da associare</h2>
  <div fxLayout="row" fxLayoutAlign="center center">
    <form [formGroup]="addPharmacyForm">
      <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="24px grid"
        class="login-form">
        <mat-form-field appearance="fill" fxFlex="calc(35% - 24px)" class="form-field">
          <mat-label>Provincia</mat-label>
          <input id="SSNinput" type="text" matInput autofocus formControlName="province" [errorStateMatcher]="matcher"
            [matAutocomplete]="auto" />
          <button type="button" tabindex="-1" mat-button *ngIf="province.value" matSuffix mat-icon-button
            aria-label="Clear" (click)="clearProvinces()">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="provinceSelected($event.option.value)">
            <ng-container *ngIf="provinces$ | async">
              <mat-option *ngFor="let option of filteredProvinces | async" [value]="option.descrizioneProvincia">
                {{ option.descrizioneProvincia }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngIf="province.hasError('required')">
            La provincia è <strong>obbligatoria</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" fxFlex="calc(65% - 24x)" class="form-field">
          <mat-label>Comune</mat-label>
          <input id="SSNinput" type="text" matInput formControlName="municipality" [errorStateMatcher]="matcher"
            [matAutocomplete]="autoMunicipality" />
          <button type="button" tabindex="-1" mat-button *ngIf="municipality.value" matSuffix mat-icon-button
            aria-label="Clear" (click)="municipality.setValue(''); pharmacy.setValue(''); pharmacy.disable()">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete autoActiveFirstOption #autoMunicipality="matAutocomplete" (optionSelected)="municipalitySelected($event.option.value)">
            <ng-container *ngIf="municipalities$ | async">
              <mat-option *ngFor="let option of filteredMunicipalities | async" [value]="option.descrizioneComune">
                {{ option.descrizioneComune }}
              </mat-option>
            </ng-container>
          </mat-autocomplete>

          <mat-error *ngIf="province.hasError('required')">
            Il comune è <strong>obbligatorio</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="24px grid"
        class="login-form">
        <mat-form-field appearance="fill" fxFlex="calc(100% - 24px)" class="form-field">
          <mat-label>Farmacia</mat-label>
          <input matInput formControlName="pharmacy" [errorStateMatcher]="matcher" [matAutocomplete]="autoPharma" />
          <button type="button" tabindex="-1" mat-button *ngIf="pharmacy.value" matSuffix mat-icon-button
            aria-label="Clear" (click)="pharmacy.setValue('')">
            <mat-icon>close</mat-icon>
          </button>

          <mat-autocomplete autoActiveFirstOption #autoPharma="matAutocomplete" [displayWith]="getPharmacyName" (optionSelected)="addSelectedPharmacy($event)">
            <mat-option *ngIf="fetchingPharmacies" class="is-loading" disabled>
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-spinner diameter="35"></mat-spinner>
              </div>
            </mat-option>
            <mat-option *ngFor="let option of filteredPharmacies | async" [value]="option" [fxHide]="fetchingPharmacies">
              <span> {{ option.denominazioneFarmacia }} </span> |
              <small> {{ option.indirizzoFarmacia }} </small>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="pharmacy.hasError('required')">
            La farmacia è <strong>obbligatoria</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <mat-progress-bar *ngIf="fetchingPharmacies" class="progress-bar-pharma" mode="query" color="accent"></mat-progress-bar> -->
    </form>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
    <mat-card class="login-form" *ngIf="selectedPharmacy">
      <div>
        <mat-card-title> {{ selectedPharmacy.denominazioneFarmacia }} </mat-card-title>
        <mat-card-subtitle> {{ selectedPharmacy.indirizzoFarmacia }} </mat-card-subtitle>
      </div>
      <mat-card-content>
        <form [formGroup]="directorEmail" (ngSubmit)="linkPharmacy(selectedPharmacy, email.value)">
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field fxFlex="100">
              <mat-label>email</mat-label>
                <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"/>
                <button type="button" tabindex="-1" mat-button *ngIf="email.value" matSuffix mat-icon-button
                  aria-label="Clear" (click)="email.setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="email.hasError('required')">
                  {{email.value}}
                  L'email è <strong>obbligatoria</strong>
                </mat-error>
  
                <mat-error *ngIf="email.hasError('email')">
                  Inserisci una email valida.
                </mat-error>
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions align="end" fxLayoutGap="16px">
        <button mat-flat-button color="primary" [disabled]="!directorEmail.valid" (click)="linkPharmacy(selectedPharmacy, email.value)"> Associa </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div class="added-pharma" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px" #linked>
    <h2>Farmacie associate</h2>
    <ng-container *ngIf="linkedPharmacies$ | async as linkedPharmacies; else loadingOrError">
      <h3 *ngIf="linkedPharmacies.length == 0" class="no-linked-text">
        Nessuna farmacia attualmente associata. Utilizza la ricerca per creare nuove associazioni
      </h3>
      <div class="error-msg" fxLayout="row" fxLayoutAlign="center center" *ngIf="errorMsg">
        <mat-icon color="warn">announcement</mat-icon>
        {{ errorMsg }}
      </div>
      <mat-card class="login-form" *ngFor="let linkedPharmacy of linkedPharmacies">
        <div>
          <mat-card-title> {{ linkedPharmacy.denominazioneFarmacia }} </mat-card-title>
          <mat-card-subtitle> {{ linkedPharmacy.IndirizzoFarmacia }} </mat-card-subtitle>
        </div>
        <mat-card-content>
          <p>
            Provincia: {{ linkedPharmacy.provinciaFarmacia }}, Comune:{{ linkedPharmacy.comuneFarmacia }}
          </p>
        </mat-card-content>
        <mat-card-actions align="end" fxLayoutGap="16px">
          <button mat-flat-button color="warn" (click)="unlinkPharmacy(linkedPharmacy)">Rimuovi</button>
        </mat-card-actions>
      </mat-card>
    </ng-container>
    <ng-template #loadingOrError>
      <ng-container *ngIf="errorMsg; else loading">
        <!-- <div class="error-msg" fxLayout="row" fxLayoutAlign="center center">
          <span> {{ errorMsg }} </span>
        </div> -->
      </ng-container>

      <ng-template #loading>
        <div fxLayout="row" fxLayoutAlign="center center" class="loader-div">
          <mat-spinner></mat-spinner>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>