<h1 mat-dialog-title>{{ data.title }}</h1>
<p style="color: #49bce2; font-size: 1.3em;" *ngIf="data.subtitle">Leggere con attenzione</p>
<div mat-dialog-content>
  <p style="white-space: pre-line; word-wrap: break-word;">{{ data.text }}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button cdkFocusInitial color="primary" mat-dialog-close>
    OK
  </button>
</div>
