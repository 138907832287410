import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecoverNREComponent } from './recover-nre/recover-nre.component';
import { LoginComponent } from './login/login.component';
import { IsAuthenticatedGuard } from './is-authenticated-guard';
import { AuthInterceptor } from './auth.interceptor';
import { AddFarmaciaComponent } from './add-farmacia/add-farmacia.component';
import { GenericDialog } from './generic-dialog/generic-dialog.component';
import { ConfirmActionDialog } from './confirm-action-dialog/confirm-action-dialog.component';

import { APP_BASE_HREF } from '@angular/common';
import { UtenteNonAbilitatoComponent } from './page/utente-non-abilitato/utente-non-abilitato.component';
import { HomeComponent } from './page/home/home.component';

function getBaseHref(): string {
  const path = window.location.pathname;
  const i = path.lastIndexOf('/');
  return i >= 0 ? path.substr(0, i + 1) : path;
}

@NgModule({
  declarations: [
    AppComponent,
    RecoverNREComponent,
    LoginComponent,
    AddFarmaciaComponent,
    ConfirmActionDialog,
    GenericDialog,
    UtenteNonAbilitatoComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDialogModule,
    MatChipsModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    FlexLayoutModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    ConfirmActionDialog,
    GenericDialog
  ],
  providers: [
    IsAuthenticatedGuard,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: APP_BASE_HREF, useValue: getBaseHref() }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
