import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MyErrorStateMatcher } from '../recover-nre/recover-nre.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable, iif, throwError, of, BehaviorSubject } from 'rxjs';
import { map, tap, startWith, debounceTime, distinctUntilChanged, switchMap, mergeMap, catchError, finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialog } from '../generic-dialog/generic-dialog.component';
import { DialogData } from '../interfaces/DialogData';
import { ToastService } from '../services/toast.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ConfirmActionDialog } from '../confirm-action-dialog/confirm-action-dialog.component';
import { ConfirmActionData } from '../interfaces/ConfirmActionData';
import { Farmacia, getLegamiFarmacista } from '../interfaces/getLegamiFarmacista';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-add-farmacia',
  templateUrl: './add-farmacia.component.html',
  styleUrls: ['./add-farmacia.component.scss']
})
export class AddFarmaciaComponent implements OnInit {

  @ViewChild('linked') linkedPharmaciesDiv: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autoPharma: MatAutocompleteTrigger;

  matcher = new MyErrorStateMatcher();

  errorMsg = '';
  counter = 0;

  filteredProvinces: Observable<string[]>;
  filteredMunicipalities: Observable<string[]>;
  filteredPharmacies: Observable<string[]>;

  fetchingPharmacies = false;

  activeComune: string = '';

  provinces$ = this.apiService.getProvinces().pipe(
    map((res: any) => {
      return res.risposta.contenutoRisposta.province.map((elem: { provincia: { codProvincia: string; descrizioneProvincia: string } }) => {
        return {
          codProvincia: elem.provincia.codProvincia,
          descrizioneProvincia: elem.provincia.descrizioneProvincia
        };
      })
    }),
    tap(res => this.provinces = res),
    // catchError((res: HttpErrorResponse) => {
    //   this.toastService.showToast(`Errore imprevisto. Riprovare più tardi.`, 'OK')
    //   return throwError(res);
    // })
  );

  municipalities$: Observable<any>;
  pharmacies$: Observable<any>;
  linkedPharmacies$ = this.apiService.getLinkedPharmacies().pipe(
    catchError((res: HttpErrorResponse) => {
      // this.toastService.showToast(`Errore imprevisto. Riprovare più tardi.`, 'OK')
      this.errorMsg = 'Errore imprevisto. Riprovare più tardi.'
      return throwError(res);
    })
  );

  provinces: { codProvincia: string; descrizioneProvincia: string; }[] = [];

  municipalities: { codComune: string; descrizioneComune: string; }[] = [];

  pharmacies: string[] = [];
  selectedPharmacy;
  linkedPharmacies: any[] = [];


  addPharmacyForm = this.formBuilder.group({
    province: ['', [Validators.required]],
    municipality: [{ value: '', disabled: true }, [Validators.required]],
    pharmacy: [{ value: '', disabled: true }, [Validators.required]]
  });

  directorEmail = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]]
  })

  public get email() {
    return this.directorEmail.get('email');
  }
  public get province() {
    return this.addPharmacyForm.get('province');
  }
  public get municipality() {
    return this.addPharmacyForm.get('municipality');
  }
  public get pharmacy() {
    return this.addPharmacyForm.get('pharmacy');
  }

  private _filter(value: any, type: string): any[] {
    const filterValue = value.toUpperCase();
    let prop = '';
    switch (type) {
      case 'provinces':
        prop = 'descrizioneProvincia';
        return this.provinces.filter(option => option[prop].indexOf(filterValue) === 0);
      case 'municipalities':
        prop = 'descrizioneComune';
        return this.municipalities.filter(option => option[prop].indexOf(filterValue) === 0);
    }
  }

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    public dialog: MatDialog,
    private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (history.state.modalTitle) this.openDialog(history.state);
    this.filteredProvinces = this.province.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value, 'provinces'))
    );
    this.filteredMunicipalities = this.municipality.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value, 'municipalities'))
    );
  }

  openDialog(state: any): void {
    const dialogRef = this.dialog.open(GenericDialog, {
      width: '90vw',
      maxWidth: '500px',
      autoFocus: true,
      data: { title: state.modalTitle, text: state.modalText, subtitle: state.subtitle ? state.subtitle : false } as DialogData
    });
  }

  provinceSelected($event) {
    if (this.province.valid) this.municipality.enable();

    const codProvincia = this.provinces[this.provinces.map(prov => prov.descrizioneProvincia).indexOf($event)].codProvincia;
    this.municipalities$ = this.apiService.getMunicipalities(codProvincia).pipe(
      map((res: any) => {
        return res.risposta.contenutoRisposta.comuni.map((elem: { comune: { codComune: string; descrizioneComune: string } }) => {
          return {
            codComune: elem.comune.codComune,
            descrizioneComune: elem.comune.descrizioneComune
          };
        })
      }),
      tap(res => this.municipalities = res)
    );
  }

  municipalitySelected($event) {
    if (this.province.valid && this.municipality.valid) this.pharmacy.enable();
    this.activeComune = this.municipalities[this.municipalities.map(comune => comune.descrizioneComune).indexOf($event)].codComune;
    this.filteredPharmacies = this.pharmacy.valueChanges.pipe(
      startWith(''),
      debounceTime(600),
      distinctUntilChanged(),
      switchMap((value: string) => {
        if (value.length > 1) {
          this.counter++;
          this.fetchingPharmacies = true;
          return this.apiService.getPharmaciesAutocomplete(this.activeComune, value).pipe(
            map((res: any) => {
              if (res.risposta.contenutoRisposta) {

                return res.risposta.contenutoRisposta.farmacie.map((elem: Farmacia) => {
                  return {
                    internalIDFarmacia: elem.farmacia.internalIDFarmacia,
                    indirizzoFarmacia: elem.farmacia.IndirizzoFarmacia,
                    denominazioneFarmacia: elem.farmacia.denominazioneFarmacia
                  };
                });
              }
            }),
            finalize(() => {
              this.counter--;
              if (this.counter === 0) this.fetchingPharmacies = false
            })
          );
        } else return []
      }),
    );
  }

  getPharmacyName(option) {
    return option.denominazioneFarmacia;
  }

  addSelectedPharmacy($event) {
    this.selectedPharmacy = $event.option.value;
    // this.pharmacy.setValue('');
    this.email.setValue('');
  }

  clearProvinces() {
    this.province.setValue('');
    this.municipality.setValue('');
    this.municipality.disable();
    this.pharmacy.disable();
  }

  linkPharmacy(pharmacy: any, email: string) {
    this.errorMsg = '';
    const dialogRef = this.dialog.open(ConfirmActionDialog, {
      width: '90vw',
      maxWidth: '500px',
      autoFocus: true,
      data: { title: `Vuoi associare al tuo profilo la farmacia ${pharmacy.denominazioneFarmacia}?` } as ConfirmActionData
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.linkedPharmaciesDiv.nativeElement.scrollIntoView();
        this.linkedPharmacies$ = this.apiService.editLegameFarmacia(pharmacy.internalIDFarmacia, 'INSERIMENTO', email).pipe(
          tap((res: any) => {
            if (res.risposta.errore !== null) {
              this.errorMsg = res.risposta.errore.descrizione;
              this.toastService.showToast(`${res.risposta.errore.codice}: ${res.risposta.errore.descrizione}`, 'OK')
            } else {
              this.selectedPharmacy = null;
              this.pharmacy.setValue('');
            }
          }),
          // mergeMap(res => iif(
          //   () => res.risposta.errore === null,
          //   this.authService.getLinkedPharmacies())
          // ),
          switchMap(res => this.apiService.getLinkedPharmacies().pipe(
            tap((res: any) => {
              if (!this.errorMsg && res.length == 1) {
                const text = `Adesso puoi accedere a tutte le funzionalità.
  
                Ad esempio, puoi cercare le ricette.
                Utilizza il menù in alto a sinistra (≡).`;
                this.openDialog({ modalTitle: 'Operazione completata', modalText: text })
              }
            })
          )),
          catchError((res: HttpErrorResponse) => {
            console.log('aaatatataat')
            // this.toastService.showToast(`Errore imprevisto. Riprovare più tardi.`, 'OK')
            this.errorMsg = 'Errore imprevisto. Riprovare più tardi.';
            return throwError(res);
          })
        );
      }
    });
  }

  unlinkPharmacy(linkedPharmacy: any) {
    this.errorMsg = '';
    const dialogRef = this.dialog.open(ConfirmActionDialog, {
      width: '90vw',
      maxWidth: '500px',
      autoFocus: true,
      data: { title: `Vuoi rimuove dal tuo profilo il legame con la farmacia ${linkedPharmacy.denominazioneFarmacia}?` }
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.linkedPharmacies$ = this.apiService.editLegameFarmacia(linkedPharmacy.internalIDFarmacia, 'CANCELLAZIONE').pipe(
          tap((res: any) => {
            if (res.risposta.errore !== null) {
              this.errorMsg = res.risposta.errore.descrizione;
              this.toastService.showToast(`${res.risposta.errore.codice}: ${res.risposta.errore.descrizione}`, 'OK')
            }
          }),
          switchMap(res => this.apiService.getLinkedPharmacies()),
          catchError((res: HttpErrorResponse) => {
            // this.toastService.showToast(`Errore imprevisto. Riprovare più tardi.`, 'OK')
            this.errorMsg = 'Errore imprevisto. Riprovare più tardi.';
            return throwError(res);
          })
        );
      }
    })
  }
}
