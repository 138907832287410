import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { AuthorityManagerService } from 'src/app/services/authority-manager.service';
import * as moment from 'moment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService, private http: HttpClient, private authorityManagerService: AuthorityManagerService) { }

  location;
  accessToken;
  refreshToken;
  operatoreCup;
  isLoading;
  avvisoDisservizio: Boolean;


  ngOnInit(): void {
    this.isLoading = this.authService.getIsLoading();
    let dataOggi = moment();
    this.avvisoDisservizio = !this.controllaTemporizzazioneAvvisoDisservizio(dataOggi);
  }

  controllaTemporizzazioneAvvisoDisservizio(dataOggi): Boolean {
    let dataPredefinita = moment('02-11-2021 09:00', 'DD-MM-YYYY hh:mm');
    return dataOggi.isAfter(dataPredefinita);
  }

  accedi(): void {
    if (sessionStorage.getItem('accessToken') != null) {
      this.isLoading = true;
      this.authService.getProfile();
    } else if (sessionStorage.getItem('accessToken') === null) {
      this.authService.authenticate();
    }
  }

}
