import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, finalize, startWith, catchError, switchMap, single } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from '../services/auth.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTooltip } from '@angular/material/tooltip';
import { ApiService } from '../services/api.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-recover-nre',
  templateUrl: './recover-nre.component.html',
  styleUrls: ['./recover-nre.component.scss']
})
export class RecoverNREComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  @ViewChild(MatTooltip) tooltip: MatTooltip;

  options: string[] = JSON.parse(sessionStorage.getItem('options')) || [];
  filteredOptions: Observable<string[]>;

  singoloEsteso = false; // se fai estendi e ti restituisce solo 1 ricetta
  loading = true;
  singleNRE = true;
  singleNRECard = {};
  singleImageBase64: SafeUrl;
  singlePdfBase64: SafeUrl;
  base64Prefix = 'data:image/png;base64,';
  pdfBase64: string;
  singlePdfFilename = 'sample.pdf';
  errorMsg = '';
  errorMsgRicettaNonConsultabile = '';
  errorMsgSingle = '';
  stampaRicetteTrasportiBoolean = false;
  codiceFiscale = '';
  nre = '';

  headers = new HttpHeaders();
  clickedDownLoad = false;
  sdmUrl = this.authService.getSDMUrl();

  statiAccettati = ["Prenotata", "Prescritta", "PresaInCarico", "ErogataParziale"]

  private SSNRegex: RegExp = /[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}?/;
  private onlyNumbers: RegExp = /^[0-9]*$/;

  private NREList$: Observable<any>;
  private NRESingleArr$: Observable<any>[];

  recoverForm = this.formBuilder.group({
    SSN: ['', [Validators.required, Validators.pattern(this.SSNRegex)]],
    last5NREDigits: ['', [Validators.required, Validators.minLength(5)]]
  });

  public get SSN() {
    return this.recoverForm.get('SSN');
  }
  public get last5NREDigits() {
    return this.recoverForm.get('last5NREDigits');
  }

  private openedLoadedArr: any[] = [];
  private openedStateArr: boolean[] = [];
  private printedPdfArr: boolean[] = [];
  private currentSSN = '';
  submitted = false;
  extended = false;
  NREList: any = [];

  accessToken = '';
  matcher = new MyErrorStateMatcher();

  constructor(private apiService: ApiService, private formBuilder: FormBuilder, private http: HttpClient, private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private location: Location, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.filteredOptions = this.SSN.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toUpperCase();
    return this.options.filter(option => option.toUpperCase().indexOf(filterValue) === 0);
  }

  private valutaMessaggioDierrore(risposta) {
    switch (risposta.statoRicetta) {
      case "Annullata":
      case "Sospesa":
      case "Erogata":
      case "Scaduta":
        return risposta.statoRicettaDescr;
      case "ErogataDopoAnnullamento":
        return "Ricetta erogata"
      case "Consolidata":
        return "Ricetta consolidata non modificabile"
      default:
        return "Nessun promemoria trovato"
    }
  }

  public submit(NRE?: string) {
    this.extended = false;
    this.singoloEsteso = false;
    this.clickedDownLoad = false;
    this.errorMsg = '';
    this.errorMsgRicettaNonConsultabile = '';
    this.submitted = true;
    this.singleNRE = true;

    this.stampaRicetteTrasportiBoolean = false;
    this.printedPdfArr = [];
    this.openedLoadedArr = [];
    this.openedStateArr = [];
    this.NRESingleArr$ = [];
    this.NREList$ = null;

    const codiceFiscale = NRE ? this.currentSSN : (this.recoverForm.value.SSN as string).toUpperCase();
    const nre = NRE ? NRE : this.recoverForm.value.last5NREDigits;
    this.autocomplete.closePanel();

    const globalRegex = new RegExp('R{1}T{1}[0-9]{4}R{1}[0-9]{8}', 'g');

    if (globalRegex.test(nre)) {
      this.apiService.getPromemoriaRicetteTrasporti(codiceFiscale, nre).subscribe(result => {
        if (result.byteLength > 2) {
          var newBlob = new Blob([result], { type: 'application/pdf' })
          const data = window.URL.createObjectURL(newBlob);
          var link = document.createElement('a');
          link.href = data;
          link.download = nre + '.pdf';
          link.click();
          this.codiceFiscale = codiceFiscale;
          this.nre = nre;
          this.loading = false;
          this.submitted = true;
          this.SSN.setValue('');
          this.last5NREDigits.setValue('');
          document.getElementById('SSNinput').focus();
          this.autocomplete.closePanel();
          this.stampaRicetteTrasportiBoolean = true;
        }
      })

    } else {
      this.NREList$ = this.apiService.getPromemoria(codiceFiscale, nre).pipe(
        // this.generateArrayNRE,
        // map((res: any) => res.risposta.contenutoRisposta.risposta),
        // map((res: any) => res.risposta),
        map((res: any) => {
          this.errorMsg = '';
          if (res.contenutoRisposta == null) {
            this.errorMsg = res.errore.descrizione;
            return [];
          }

          if (Object.keys(res.contenutoRisposta.risposta).length == 0) {
            this.errorMsg = 'Nessun promemoria trovato.';
            // this.errorMsg = 'Nessun altro promemoria trovato.'; // per il caso di allarga ricerca
            return [];
          }
          if (!res.contenutoRisposta.risposta.lista) {
            this.errorMsg = 'Nessun promemoria trovato.';
            return [];
          }

          if (res.contenutoRisposta.risposta.lista.length > 1) {
            this.singleNRE = false;
            // return res.contenutoRisposta.risposta.lista.map(item => item.nre.valore)
          } else {
            if (!this.statiAccettati.find(item => item == res.contenutoRisposta.risposta.statoRicetta)) {
              this.codiceFiscale = codiceFiscale;
              this.nre = res.contenutoRisposta.risposta.lista[0]["nre"]["valore"];

              this.errorMsgRicettaNonConsultabile = this.valutaMessaggioDierrore(res.contenutoRisposta.risposta);

              return [];
            } else {
              this.singleNRECard = res.contenutoRisposta.risposta;

              this.pdfBase64 = res.contenutoRisposta.risposta.pdfRicettaBase64; // da togliere
              this.singleImageBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.contenutoRisposta.risposta.imageRicettaBase64}`);
              this.singlePdfBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:application/octet-stream;base64,${res.contenutoRisposta.risposta.pdfRicettaBase64}`);
              this.singlePdfFilename = res.contenutoRisposta.risposta.pdfFileName;
            }
          }
          return res.contenutoRisposta.risposta.lista.map(item => item.nre.valore);
        }),
        // map((res: any) => res.lista.map(item => item.nre.valore)),
        // catchError((res) => {

        // }),
        finalize(() => {
          this.loading = false;
          if (!NRE) this.currentSSN = (this.recoverForm.value.SSN as string).toUpperCase();

          if (!this.errorMsg) {

            if (this.SSN.value.length == 16 && !this.options.includes(this.SSN.value)) {
              if (this.options.length == 5) {
                this.options.pop();
              }
              this.options.unshift(this.recoverForm.value.SSN.toUpperCase());
              sessionStorage.setItem('options', JSON.stringify(this.options));
            }

            this.SSN.setValue('');
            this.last5NREDigits.setValue('');
            document.getElementById('SSNinput').focus();
            this.autocomplete.closePanel();

          } else {
            // this.SSN.setValue('');
            // this.last5NREDigits.setValue('');
            document.getElementById('SSNinput').focus();
            this.autocomplete.closePanel();
          }

        }),
        catchError((response: HttpErrorResponse) => {
          if (response.error.detail) {
            this.errorMsg = `${response.error.detail}\nRiprovare, per favore.`;
          } else {
            this.errorMsg = 'Errore imprevisto,\nRiprovare, per favore.';
          }
          return throwError(response);
        })
      );
    }





  }

  public searchSingleNRE(NRE: string, i: number) {
    if (this.openedStateArr[i]) return;
    this.openedStateArr[i] = true;
    this.errorMsgSingle = '';

    const codiceFiscale = this.currentSSN;
    const nre = NRE;

    let singleNRECard: any = {};

    const NRESingle$ = this.apiService.getPromemoria(codiceFiscale, nre).pipe(
      // this.generateArrayNRE,
      // map((res: any) => res.risposta.contenutoRisposta.risposta),
      // map((res: any) => res.risposta),
      map((res: any) => {
        this.errorMsg = '';
        if (res.contenutoRisposta == null) {
          this.errorMsg = res.errore.descrizione;
          return [];
        }

        if (Object.keys(res.contenutoRisposta.risposta).length == 0) {
          this.errorMsgSingle = 'Nessun promemoria trovato.';
          // this.errorMsg = 'Nessun altro promemoria trovato.'; // per il caso di allarga ricerca
          return [];
        }

        if (res.contenutoRisposta.risposta.lista.length > 1) {
          // this.singleNRE = false;
          // return res.contenutoRisposta.risposta.lista.map(item => item.nre.valore)
        } else {
          // this.singleNRECard = res.contenutoRisposta.risposta;

          singleNRECard.singleImageBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.contenutoRisposta.risposta.imageRicettaBase64}`);
          singleNRECard.singlePdfBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:application/octet-stream;base64,${res.contenutoRisposta.risposta.pdfRicettaBase64}`);
          singleNRECard.singlePdfFilename = res.contenutoRisposta.risposta.pdfFileName;

          this.openedLoadedArr[i] = singleNRECard;
        }
        return res.contenutoRisposta.risposta.lista.map(item => item.nre.valore)
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.error.detail) {
          this.errorMsgSingle = `${response.error.detail}\nRiprovare, per favore.`;
        } else {
          this.errorMsgSingle = 'Errore imprevisto,\nRiprovare, per favore.';
        }
        return throwError(response);
      })
    );
    this.NRESingleArr$[i] = NRESingle$;
  }

  public extendSearch(NRE: string) {
    this.extended = true;
    this.errorMsg = '';
    this.submitted = true;
    this.singleNRE = true;

    const codiceFiscale = this.currentSSN;
    const nre = NRE;

    this.NREList$ = this.apiService.getEspandiPromemoria(codiceFiscale, nre).pipe(
      // this.generateArrayNRE,
      // map((res: any) => res.risposta.contenutoRisposta.risposta),
      map((res: any) => {
        this.errorMsg = '';
        if (res.contenutoRisposta == null) {
          this.errorMsg = res.errore.descrizione;
          return [];
        }

        if (Object.keys(res.contenutoRisposta.risposta).length == 0) {
          this.errorMsg = 'Nessun altro promemoria trovato allargando la ricerca.';
          return [];
        }

        if (res.contenutoRisposta.risposta.lista.length > 1) {
          this.singleNRE = false;
          // return res.contenutoRisposta.risposta.lista.map(item => item.nre.valore)
          this.openedStateArr = res.contenutoRisposta.risposta.lista.map(() => false);
          this.printedPdfArr = res.contenutoRisposta.risposta.lista.map(() => false);

          this.openedLoadedArr = [];
          this.NRESingleArr$ = res.contenutoRisposta.risposta.lista.map(() => null);
        } else {
          this.singoloEsteso = true;

          this.singleNRECard = res.contenutoRisposta.risposta;

          this.pdfBase64 = res.contenutoRisposta.risposta.pdfRicettaBase64; // da togliere
          this.singleImageBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.contenutoRisposta.risposta.imageRicettaBase64}`);
          this.singlePdfBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:application/octet-stream;base64,${res.contenutoRisposta.risposta.pdfRicettaBase64}`);
          this.singlePdfFilename = res.contenutoRisposta.risposta.pdfFileName;
        }
        return res.contenutoRisposta.risposta.lista.map(item => {
          return {
            nre: item.nre.valore,
            dataPrescrizione: item.nre.dataPrescrizione ? item.nre.dataPrescrizione : ''
          }
        })
      }),
      finalize(() => {
        this.loading = false;
        // if (this.singleNRE) {
        this.SSN.setValue('');
        document.getElementById('SSNinput').focus();
        this.autocomplete.closePanel();
        // }
        this.last5NREDigits.setValue('');
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.error.detail) {
          this.errorMsg = `${response.error.detail}\nRiprovare, per favore.`;
        } else {
          this.errorMsg = 'Errore imprevisto,\nRiprovare, per favore.';
        }
        return throwError(response);
      })
    );
  }

  // private generateArrayNRE = (res: any) => {
  //   if (res.errore) {
  //     this.errorMsg = res.errore.descrizione;
  //     return [];
  //   }

  //   if (!res.contenutoRisposta) {
  //     this.errorMsg = 'Nessun NRE trovato.';
  //     return [];
  //   }

  //   if (res.lista.length > 1) {
  //     this.singleNRE = false;
  //   } else { 
  //     this.singleNRECard = res;
  //     this.singleImageBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${res.imageRicettaBase64}`);
  //     this.singlePdfBase64 = this.sanitizer.bypassSecurityTrustUrl(`data:application/octet-stream;base64,${res.pdfRicettaBase64}`);
  //     this.singlePdfFilename = res.pdfFileName;
  //   }
  //   res = res.lista;
  //   return res;
  // }

  splitNRE(NRE: string, length: number) {
    if (length == 10) {
      return `${NRE.slice(0, 10)}`;
    }
    return `${NRE.slice(10, 15)}`;
  }

  // private SSNValidator(control) {
  //   if (!control.value.match(this.SSNRegex)) {
  //     return { invalidSSN: true };
  //   }
  //   return null;
  // }

  public flagAsPrinted(i: number) {
    this.printedPdfArr[i] = true;
  }

  public async copyToClipboard(nre: string) {
    if (!navigator.clipboard) {
      return;
    }

    try {
      await navigator.clipboard.writeText(nre);
      this.tooltip.show();
    } catch (err) {
      console.error('copia incolla fallito', err)
    }
  }
}
